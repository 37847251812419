import axios from "axios";
import { toast } from "react-hot-toast";
import { API_URL, GATSBY_SIGNATURE } from "./config";
import { CustomGtag } from "./custom_gtag";
import { handleError } from "./error";

export const saveContact = async (contactData: ContactData) => {
  try {
    await axios.post(`${API_URL}/contacts`, {
      ...contactData,
      signature: GATSBY_SIGNATURE,
    });

    toast.success(
      "We have received your message! Our team will reach you in the next 24 hours...",
    );
    CustomGtag({ action: "Success contact message" });
  } catch (e) {
    handleError(e);
  }
};

export interface ContactData {
  email: string;
  name: string;
  message: string;
  phone: string;
  company: string;
}
