// form
import React from "react";
import { useFormContext, Controller } from "react-hook-form";

// ----------------------------------------------------------------------

interface IProps {
  name: string;
  type?: string;
  placeholder?: string;
  defaultValue?: string;
  className?: string;
  required?: boolean;
}

export default function RHInput({ name, ...other }: IProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <label htmlFor={name} style={{ display: "block" }}>
          <input
            id={name}
            {...field}
            {...other}
            placeholder={
              typeof other.placeholder !== "undefined" && error == null
                ? other.placeholder
                : error?.message
            }
            className={
              error != null
                ? `${
                    typeof other.className !== "undefined"
                      ? other.className
                      : ""
                  } input-error`
                : other.className
            }
          />
          {error != null && (
            <span style={{ color: "red", paddingTop: "10px" }}>
              {error.message}
            </span>
          )}
        </label>
      )}
    />
  );
}
