import React, { useMemo } from "react";
import { FormProvider } from "@/hook-form";
import RHInput from "@/hook-form/RHInput";
import RHTextarea from "@/hook-form/RHTextarea";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { saveContact, type ContactData } from "@/npc-api/contact";

const FormC = () => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const ContactSchema = Yup.object().shape({
    name: Yup.string().max(50).required("Name is required"),
    phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
    email: Yup.string().required("Email is required").email(),
    company: Yup.string(),
    message: Yup.string().required("Message is required"),
  });

  const defaultValues = useMemo(
    () => ({
      name: "",
      phone: "",
      email: "",
      company: "",
      message: "",
    }),

    [],
  );

  const methods = useForm<ContactData>({
    resolver: yupResolver(ContactSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: ContactData) => {
    await saveContact(data).then((e) => {
      reset({ name: "", phone: "", email: "", company: "", message: "" });
    });
  };
  return (
    <div className="rts-contact-form-area">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="rts-contact-fluid rts-section-gap">
              <div className="rts-title-area contact-fluid text-center mb--50">
                <p className="pre-title">Get In Touch</p>
                <h2 className="title">Need Help? Let’s Get in Touch</h2>
              </div>
              <div className="form-wrapper">
                <div id="form-messages"></div>
                <FormProvider
                  name="contactform"
                  methods={methods}
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <RHInput
                        name="name"
                        type="text"
                        placeholder="Your Name"
                        required={true}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <RHInput
                        name="email"
                        type="email"
                        placeholder="Your Email"
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <RHInput
                        name="company"
                        type="text"
                        placeholder="Company Name"
                      />
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <RHInput
                        name="phone"
                        type="text"
                        placeholder="Phone Number"
                        required={true}
                      />
                    </div>
                  </div>

                  <RHTextarea
                    name="message"
                    placeholder="Your Message"
                    row={3}
                  />
                  <button
                    disabled={isSubmitting}
                    type="submit"
                    className="rts-btn btn-primary"
                  >
                    {`${isSubmitting ? "Loading" : "Send Message"}`}
                  </button>
                </FormProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormC;
