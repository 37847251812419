import React from "react";
import ContactSingle from "./ContactSingle";
import FormC from "./Form";
import MapC from "./Map";
import Partners from "../Home/Partners";

const ContactPP = () => {
  return (
    <>
      <ContactSingle />
      <MapC />
      <FormC />
      <Partners />
    </>
  );
};

export default ContactPP;
