import React from "react";

const ContactSingle = () => {
  return (
    <div className="rts-contact-area rts-section-gap">
      <div className="container">
        <div className="row g-5">
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
            <div className="single-contact-one-inner position-relative contact-box">
              <div className="content">
                <div className="icone">
                  <img src="/images/contact/shape/01.svg" alt="" />
                </div>
                <div className="info">
                  <span>Call Us 24/7</span>
                  <a href="tel:+13055928807">
                    <h5>Phone: (305) 592-8807</h5>
                  </a>
                  <a href="fax:+13054686495">
                    <h5>Fax: (305) 468-6495</h5>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
            <div className="single-contact-one-inner  position-relative contact-box">
              <div className="content">
                <div className="icone">
                  <img src="/images/contact/shape/02.svg" alt="" />
                </div>
                <div className="info">
                  <span>Make A Quote</span>
                  <a href="mailto:sales@mekcogroup.com">
                    <h5>sales@mekcogroup.com</h5>
                  </a>
                  <a href="mailto:customerservice@mekcogroup.com">
                    <h5>customerservice@mekcogroup.com</h5>
                  </a>
                  <a href="mailto:repairs@mekcogroup.com">
                    <h5>repairs@mekcogroup.com</h5>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
            <div className="single-contact-one-inner position-relative contact-box ">
              <div className="content">
                <div className="icone">
                  <img src="/images/contact/shape/03.svg" alt="" />
                </div>
                <div className="info">
                  <span>Location</span>
                  <a href="https://goo.gl/maps/wdvJCoMUwYcnqAR57">
                    <h5>2775 NW 82 Ave, Doral, Florida 33122</h5>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSingle;
