import React from "react";

const MapC = () => {
  return (
    <div className="rts-contact-map-area">
      <div className="contaciner-fluid">
        <div className="row">
          <div className="col-12">
            <div className="contact-map-area-fluid">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3592.1239482359474!2d-80.32959648574426!3d25.79948461333384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b970cb998b87%3A0x7ee6ae07a589fae8!2s2775%20NW%2082nd%20Ave%2C%20Doral%2C%20FL%2033122!5e0!3m2!1ses-419!2sus!4v1680621290792!5m2!1ses-419!2sus"
                height="450"
                style={{ border: 0, height: "502px" }}
                allowFullScreen={true}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapC;
