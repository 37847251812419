import Breadcrumb from "@/components/Common/Breadcrumb";
import ContactPP from "@/components/Contact";
import Layout from "@/components/Layout";
import React from "react";
import { useLocation } from "@reach/router";
import SEO from "@/components/seo";

const ContactP = () => {
  return (
    <Layout>
      <SEO title="Contact" canonical={`${useLocation().host}`} />
      <Breadcrumb
        imgUrl="/images/careers/v1.jpeg"
        name="Contact Us"
        pathName="Contact"
      />
      <ContactPP />
    </Layout>
  );
};

export default ContactP;
